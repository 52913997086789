var CONFUSEDCMS = CONFUSEDCMS || {},
	CSSCONSTANTS = CSSCONSTANTS || {};
CONFUSEDCMS.eventDecorator = CONFUSEDCMS.eventDecorator || {};
CONFUSEDCMS.eventDecorator.events = CONFUSEDCMS.eventDecorator.events || {};
CONFUSEDCMS.eventDecorator.functions = CONFUSEDCMS.eventDecorator.functions || {};

// Don't load any of thesse events until the document is loaded as some have external dependancies
document.addEventListener('DOMContentLoaded', function () {
	// The console logger is a diagnostic event that simnply echos out the supplied message to the browsers console.
	(function (ns) {
		'use strict';

		// Log click to Console sample EventDecorator Event, this is used for unit test
		ns.LogToConsole = function (message) {
			window.console.log(message);
		};

		// If you have a function that has no external dependancies, add it here...
	})(CONFUSEDCMS.eventDecorator.functions);

	// 21cMI & GA
	(function (ns, miListener) {
		'use strict';

		// Function to gather event data from static data attributes on the DOM
		function getStaticMiInfo(data) {
			let eventInfo = {},
				label,
				trimmedLabel;

			for (label in data) {
				if (!data.hasOwnProperty(label)) {
					continue;
				}

				if (label === 'miEventType' || label === 'miClickLabel') {
					continue;
				}

				//only care about MI data, providing it's not the event type
				if (label.match(/^mi/)) {
					//remove the leading 'mi' and copy data across to hash
					trimmedLabel = label.replace('mi', '');
					eventInfo[trimmedLabel] = data[label];
				}
			}

			return eventInfo;
		}

		ns.miClickEvent = function () {
			try {
				//var attributeData = $(this).data();
				const attributeData = {};
				for (const attr of this.attributes) {
					if (attr.name.startsWith('data-')) {
						// Convert "data-example-name" to "exampleName"
						const propName = attr.name.substring(5).replace(/-./g, m => m[1].toUpperCase());
						attributeData[propName] = attr.value;
					}
				}

				let category = 'not-set',
					label = 'not-set';
				if (attributeData.miClickCategory !== '') {
					category = attributeData.miClickCategory;
				}

				if (attributeData.miClickLabel !== '') {
					label = attributeData.miClickLabel;
				}

				const miEvent = `CMS` + `::${category}::${label}::` + `click`;
				miListener.postEvent(miEvent, getStaticMiInfo(attributeData));

				if (!window.ga) {
					console.log(
						'Error calling Google Analytics in CONFUSEDCMS.eventDecorator.events.miClickEvent: gaq undefined'
					);
					return;
				}

				window.ga('send', attributeData.gaClickCategory, 'click', attributeData.gaClickLabel);
			} catch (e) {
				console.log(`Error calling MI Click Event in CONFUSEDCMS.eventDecorator.events.miClickEvent: ${e}`);
			}
		};
	})(CONFUSEDCMS.eventDecorator.events, CONFUSEDCORE.MI.MiListener);

	// If you have a function that has external dependancies, add it here in its own closure
	// with the dependancy passed in as an argument (such as in data layer above)

	// Process Static Events
	(function (e) {
		//var htmlDoc = jQuery('#html');

		const htmlDoc = document.querySelector('html');
		if (!htmlDoc.classList.contains('sitecore-editor')) {
			e.EventDefinitionList.Add(
				new e.EventDefinition(
					'[data-mi-event-type="click"]',
					'click',
					'CONFUSEDCMS.eventDecorator.events.miClickEvent'
				)
			);
			e.EventDefinitionList.ProcessAll();
		}
	})(CONFUSEDCMS.eventDecorator);
});
