/*Version added for use only by CMSv2  */

var CONFUSEDCORE = CONFUSEDCORE || {};
CONFUSEDCORE.MI = CONFUSEDCORE.MI || {};

/*
@desc: a listener for management information events
		this listener will run itself
 */
CONFUSEDCORE.MI.MiListener = (function (fetchFacade) {
	const options = {
		//The URL to post the MI event to
		MI_EVENT_URL: 'miv2/event',
		MI_PAGEVIEW_URL: 'miv2/pageview',
		MI_NEWSESSION_URL: 'miv2/newsession',
	};

	function doPost(miUrl, miObject, callback, errorCallback) {
		const url = `${window.location.protocol}//${window.location.host}/${miUrl}`;
		fetchFacade.post(
			url,
			{
				success: function () {
					if (callback) {
						callback();
					}
				},
				error: function () {
					if (errorCallback) {
						errorCallback();
					}
				},
			},
			miObject
		);
	}

	function postEvent(eventType, eventInfo, callback, errorCallback) {
		doPost(
			options.MI_EVENT_URL,
			{
				EventType: eventType,
				EventInfo: eventInfo,
			},
			callback,
			errorCallback
		);
	}

	function postPageView(eventType, eventInfo, callback, errorCallback) {
		doPost(
			options.MI_PAGEVIEW_URL,
			{
				EventType: eventType,
				EventInfo: eventInfo,
			},
			callback,
			errorCallback
		);
	}

	function pageView(pageName) {
		postPageView('LogPageView', { page: pageName });
	}

	function postLogSession(eventType, eventInfo, callback, errorCallback) {
		doPost(
			options.MI_NEWSESSION_URL,
			{
				EventType: eventType,
				EventInfo: eventInfo,
			},
			callback,
			errorCallback
		);
	}

	return {
		/*
        @desc: post the MI event
        @param: eventType: the event type
        @param: eventInfo: the event information object
         */
		postEvent: postEvent,
		postPageView: postPageView,
		pageView: pageView,
		postLogSession: postLogSession,

		/*
        @desc: override the default MI URL
        @param: url: the new url
        @default: mi/event
        */
		setMI_URL: function (url) {
			options.MI_URL = url;
		},
	};
})(CONFUSEDCORE.AJAX.Fetchfacade);
