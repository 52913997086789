var CONFUSEDCORE = CONFUSEDCORE || {};
CONFUSEDCORE.AJAX = CONFUSEDCORE.AJAX || {};

CONFUSEDCORE.AJAX.Fetchfacade = (function () {
	function userIsLoggedIn(response) {
		if (response && response.Authenticated === false) {
			window.location.reload();
			return false;
		}
		return true;
	}

	function error(options, xhr, textStatus, errorThrown) {
		if (!options) {
			return;
		}

		if (options.error) {
			options.error(xhr, textStatus, errorThrown);
		}
	}

	// Capturing CCID from postPageView response and pushing to the dataLayer for GA
	const passCCIDToDataLayer = response => {
		if ('CustomerCookieId' in response) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({ ccid: response.CustomerCookieId });
		}
	};

	function handleResponse(response, options) {
		if (response.CustomerCookieId && response.CustomerCookieId != '') {
			passCCIDToDataLayer(response);
		}

		if (response.redirect) {
			document.location = response.redirect;
			return;
		}

		if (options.wrappedResponse) {
			if (!response || response.Status !== 'ok') {
				error(options, null, 'error', 'Response status not ok');
				return;
			}

			if (options.requiresAuthentication) {
				if (!userIsLoggedIn(response)) {
					return;
				}
			}

			if (options.success) {
				options.success(response.Data);
			}
		} else {
			options.success(response);
		}
	}

	return {
		post: function (url, options, data) {
			if (!options.timeoutInterval) {
				options.timeoutInterval = 5000; // default to 5s
			}

			options.async = options.async === null || options.async === undefined ? true : options.async;
			options.dataType = options.dataType || 'json';
			options.wrappedResponse = options.wrappedResponse === undefined ? true : options.wrappedResponse;

			const controller = new AbortController();
			const signal = controller.signal;
			setTimeout(() => controller.abort(), options.timeoutInterval);

			fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
				signal: signal,
			})
				.then(response => {
					if (!response.ok) throw new Error('Network response was not ok');
					return options.dataType === 'json' ? response.json() : response.text();
				})
				.then(data => {
					handleResponse(data, options);
				})
				.catch(err => {
					if (err.name === 'AbortError') {
						if (options.timeout) {
							options.timeout();
						}
					} else {
						error(options, null, 'error', err.message);
					}
				})
				.finally(() => {
					if (typeof options.complete !== 'undefined') {
						options.complete();
					}
				});
		},

		postAuthenticated: function (url, options, data) {
			if (!options) {
				options = {};
			}
			options.requiresAuthentication = true;
			this.post(url, options, data);
		},
	};
})();
