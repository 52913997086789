var CONFUSEDCMS = CONFUSEDCMS || {};
CONFUSEDCMS.eventDecorator = CONFUSEDCMS.eventDecorator || {};

if (!window.console) {
	window.console = { log: function () {} };
}

// EventDefinition
(function (ns) {
	'use strict';

	ns.EventDefinition = function (selector, eventName, functionName, params) {
		if (!(this instanceof ns.EventDefinition)) {
			return new ns.EventDefinition(selector, eventName, functionName, params);
		}
		this.selector = selector;
		this.eventName = eventName;
		this.functionName = functionName;
		this.params = params;

		return this;
	};
})(CONFUSEDCMS.eventDecorator);

// EventDefinitionList
(function (ns) {
	'use strict';
	ns.EventDefinitionList = [];
	ns.EventDefinitionList.Add = function (eventDefinition) {
		if (eventDefinition.constructor === ns.EventDefinition) {
			this.push(eventDefinition);
		}
	};
	ns.EventDefinitionList.ProcessAll = function () {
		// careful here, we are slightly mixing metaphors! had to use a variable cos length property changes each iteration!
		const length = ns.EventDefinitionList.length;
		for (let i = 0; i < length; i++) {
			const eventDefinition = ns.EventDefinitionList.pop();
			if (eventDefinition.selector === '') {
				ns.ProcessFunction(eventDefinition);
			} else {
				ns.ProcessEvent(eventDefinition);
			}
		}
	};
})(CONFUSEDCMS.eventDecorator);

// Process a single event definition by finding the function and wiring up the event to fire it
(function (ns, w) {
	'use strict';

	// functions in name spaces cannot be directly referenced, so
	// we need to dig the function out of the context (usually the window object)
	function getFunctionByName(functionName, context) {
		const namespaces = functionName.split('.');
		const func = namespaces.pop();
		for (let i = 0; i < namespaces.length; i++) {
			context = context[namespaces[i]];
		}
		return context[func];
	}

	ns.ProcessEvent = function (eventDefinition) {
		// first get the function out of the supplied namespace
		const fn = getFunctionByName(eventDefinition.functionName, w);
		if (typeof fn !== 'function') {
			return false;
		}

		// Now wire up the function to the requested event name, for a given selector, passing in any require parameters.
		document.body.addEventListener(eventDefinition.eventName, function (event) {
			const element = event.target;
			const parentElement = element.parentElement; // Get the direct parent element

			// Check if the clicked element itself matches the selector
			if (element.matches(eventDefinition.selector)) {
				fn.apply(element, eventDefinition.params);
			}
			// Otherwise, check if the parent element matches the selector
			else if (parentElement && parentElement.matches(eventDefinition.selector)) {
				fn.apply(parentElement, eventDefinition.params);
			}
		});

		return true;
	};

	ns.ProcessFunction = function (eventDefinition) {
		// first get the function out of the supplied namespace
		const fn = getFunctionByName(eventDefinition.functionName, w);

		if (typeof fn !== 'function') {
			return false;
		}
		fn.apply(fn, eventDefinition.params);
		return true;
	};
})(CONFUSEDCMS.eventDecorator, window);
